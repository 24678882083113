import { useEffect, useRef } from "react";
import PhraseContext from "../../../context/phraseContext";

const Parallax = () => {
    const phraseRef = useRef(null);

    useEffect(() => {
        PhraseContext.height = phraseRef.current.previousElementSibling.clientHeight;
    }, []);

    window.addEventListener("resize", () => {
        if (phraseRef?.current) {
            PhraseContext.height = phraseRef.current.previousElementSibling.clientHeight;
        }
    });

    return phraseRef;
}

export default Parallax;