import React from 'react';
import { Slide } from 'react-slideshow-image';
import PropTypes from 'prop-types';
import 'react-slideshow-image/dist/styles.css';

import logo from '../../../assets/img/logos/Fastuosa_logo_01.png';

import './Banner.css';


const Banner = ({ imagenes, isHomeSlider = false }) => {
  const images = imagenes;
  const isHome = isHomeSlider;

  const loadSlider = () => {
    return images.map(imgSlide => {
      return (
        <section className="each-slide-effect" key={imgSlide.id}>
          <div className='each-slide-effect_slide ' style={{ 'backgroundImage': `url(${imgSlide.img})` }}>
            {isHome && (
              <div className='slide_container' >
                <img loading="lazy" className="slide_container_img" src={logo} alt="Logo" />
                <p className='slide_container_text' >Restaurante familiar</p>
                <span className='slide_container_span'>con más de 20 años en el mercado.</span>
              </div>
            )}
          </div>
        </section>
      );
    })
  }

  return (
    <section className='bannerHome'>
      <Slide>
        {loadSlider()}
      </Slide>
    </section>
  );
};

Banner.propTypes = {
  imagenes: PropTypes.array.isRequired,
  isHomeSlider: PropTypes.bool.isRequired,
};

export default Banner;
