import { useEffect, useState } from "react";
import Parallax from "../../Shared/Parallax/Parallax";
import imgSlider from '../../../assets/img/Menus/31.jpg';
import Slider from "../../Shared/Slider/Slider";

import './MenuPizza.css';

const MenuPizzas = () => {
    const phraseRef = Parallax();
    const [pizzaSize, setPizzaSize] = useState([])
    const [menuPizzas, setMenuPizzas] = useState([])
    const getData = () => {
        fetch('../../../../data.json'
            , {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            }
        )
            .then(response => {
                return response.json();
            })
            .then(myJson => {
                const { MENU_PIZZAS, PIZZA_SIZE } = myJson
                setMenuPizzas(MENU_PIZZAS)
                setPizzaSize(PIZZA_SIZE)
            });
    }
    useEffect(() => {
        getData()
    }, [])
    return (
        <>
            <Slider imgSlider={imgSlider} titile={"Menú Pizza´s"} isGalery={false} />
            <section className="section_menu_pizzas" ref={phraseRef} >
                <table
                    className="pizzasTable"
                >
                    <thead>
                        <tr>
                            <th>
                            </th>

                            {
                                pizzaSize.map(pizzaS => {
                                    return (
                                        <th
                                            key={pizzaS.id}
                                            className="pizzasSize"
                                        >
                                            {pizzaS.nombre}
                                        </th>
                                    )
                                })
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {
                            menuPizzas.map(pizza => {
                                return (
                                    <tr
                                        key={pizza.id}
                                    >
                                        <td>
                                            <p className="pizzaMenu_Titulo">
                                                {pizza.nombre}
                                            </p>
                                            <p className="pizzaMenu_Descripcion">
                                                {pizza.descripcion}
                                            </p>
                                        </td>
                                        {
                                            pizza.precios.map(precioPizza => {
                                                return (
                                                    <td
                                                        key={precioPizza.idPrecio}
                                                        className="pizzaMenuPrecio"
                                                    >
                                                        <p className="pizzaMenu_Precio">
                                                            ${precioPizza.precio}.00
                                                        </p>
                                                    </td>
                                                );
                                            })
                                        }
                                    </tr>
                                );
                            })
                        }
                    </tbody>
                </table>
            </section>
        </>
    );
}

export default MenuPizzas;