
// import { NavLink } from "react-router-dom";

import './Footer.css';
import fbIcon from '../../../assets/icons/facebook.png'


const Footer = () => {
    const year = new Date().getFullYear();
    return (
        <footer className="footer">
            <div className="footer_body">
                <div className="footer_body_container">
                    <p className='footer_body_titles'>
                        Teléfonos
                    </p>
                    <p className="footer_body_text">
                        Blvd. San Pedro: <a className='footer_body_tex_phone' href={"tel:+52477-711-51-46"} > 477-711-51-46 </a>, <a className='footer_body_tex_phone' href={"tel:+52477-928-29-89"} >477-928-29-89</a>
                        <br />
                        Av. Oceano Atlántico: <a className='footer_body_tex_phone' href={"tel:+52477-761-87-03"}> 477-761-87-03 </a>
                        <br />
                        Blvd. El Mayorazgo: <a className='footer_body_tex_phone' href={"tel:+52477-909-54-49"}> 477-909-54-49 </a>
                    </p>
                </div>
                <div className="footer_body_container">
                    <p className='footer_body_titles'>
                        Fastuosa restaurante.
                    </p>
                    <a href="https://www.facebook.com/Fastuosa-de-Sergios-103810384825497/" target="_blank" rel="noreferrer">
                        <img loading="lazy" src={fbIcon} alt={"Facebook Fastuosa"} className="footer_body_fbIcon" />
                    </a>
                </div>
            </div>
            <p className="footer_body_text">
                Fastuosa restaurante. León, Guanajuato, México
            </p>
            <div className="footer_bottom">
                <p>Copyright ©{year}, Todos los derechos reservados</p>
            </div>
        </footer>
    );
}

export default Footer;