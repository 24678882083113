import React, { useRef, useEffect, useState } from "react";
// import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper";

import PhraseContext from "../../../../../context/phraseContext";

import menu4 from '../../../../../assets/img/Menus/4.jpg';
import menu5 from '../../../../../assets/img/Menus/5.jpg';
import menu7 from '../../../../../assets/img/Menus/7.jpg';
import menu16 from '../../../../../assets/img/Menus/16.jpg';
import menu17 from '../../../../../assets/img/Menus/17.jpg';
import menu18 from '../../../../../assets/img/Menus/18.jpg';
import menu20 from '../../../../../assets/img/Menus/20.jpg';
import menu21 from '../../../../../assets/img/Menus/21.jpg';
import menu22 from '../../../../../assets/img/Menus/22.jpg';
import menu23 from '../../../../../assets/img/Menus/23.jpg';
import menu24 from '../../../../../assets/img/Menus/24.jpg';
import menu25 from '../../../../../assets/img/Menus/25.jpg';
import menu26 from '../../../../../assets/img/Menus/26.jpg';
import menu29 from '../../../../../assets/img/Menus/29.jpg';
import menu33 from '../../../../../assets/img/Menus/33.jpg';
import menu35 from '../../../../../assets/img/Menus/35.jpg';
import menu40 from '../../../../../assets/img/Menus/40.jpg';
import menu45 from '../../../../../assets/img/Menus/45.jpg';
import menu55 from '../../../../../assets/img/Menus/55.jpg';
import menu59 from '../../../../../assets/img/Menus/59.jpg';
import menu60 from '../../../../../assets/img/Menus/60.jpg';
import menu62 from '../../../../../assets/img/Menus/62.jpg';
import menu63 from '../../../../../assets/img/Menus/63.jpg';
import menu64 from '../../../../../assets/img/Menus/64.jpg';
import menu65 from '../../../../../assets/img/Menus/65.jpg';


// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "./Galery.css";
import Slider from "../../../../Shared/Slider/Slider";


const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}


const Galery = () => {
  const phraseRef = useRef(null);
  const { width } = useWindowDimensions();
  const GALERY_IMAGES = [
    {id: menu4, imagen: menu4, name: "menu4"},
    {id: menu5, imagen: menu5, name: "menu5"},
    {id: menu7, imagen: menu7, name: "menu7"},
    {id: menu16, imagen: menu16, name: "menu16"},
    {id: menu17, imagen: menu17, name: "menu17"},
    {id: menu18, imagen: menu18, name: "menu18"},
    {id: menu20, imagen: menu20, name: "menu20"},
    {id: menu21, imagen: menu21, name: "menu21"},
    {id: menu22, imagen: menu22, name: "menu22"},
    {id: menu23, imagen: menu23, name: "menu23"},
    {id: menu24, imagen: menu24, name: "menu24"},
    {id: menu25, imagen: menu25, name: "menu25"},
    {id: menu26, imagen: menu26, name: "menu26"},
    {id: menu29, imagen: menu29, name: "menu29"},
    {id: menu33, imagen: menu33, name: "menu33"},
    {id: menu35, imagen: menu35, name: "menu35"},
    {id: menu40, imagen: menu40, name: "menu40"},
    {id: menu45, imagen: menu45, name: "menu45"},
    {id: menu55, imagen: menu55, name: "menu55"},
    {id: menu59, imagen: menu59, name: "menu59"},
    {id: menu60, imagen: menu60, name: "menu60"},
    {id: menu62, imagen: menu62, name: "menu62"},
    {id: menu63, imagen: menu63, name: "menu63"},
    {id: menu64, imagen: menu64, name: "menu64"},
    {id: menu65, imagen: menu65, name: "menu65"},
  ];

  useEffect(() => {
    PhraseContext.height = phraseRef.current.previousElementSibling.clientHeight;
  }, []);

  window.addEventListener("resize", () => {
    if (phraseRef?.current) {
      PhraseContext.height = phraseRef.current.previousElementSibling.clientHeight;
    }
  });

  return (
    <section className="phrase" id="phraseSection" ref={phraseRef}>
      <div className="galery">
        <h3 className="galery_title">Galeria</h3>
        <Slider
          imgs={GALERY_IMAGES}
          isGalery={true}
          slidesPerView={width <= 600 ? 1 : width >= 700 && width <= 900 ? 2 : width < 1024 ? 3 : 5}
          spaceBetween={30}
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
          }}
          pagination={{
            clickable: true,
          }}
          navigation={true}
          modules={[Autoplay, Pagination, Navigation]}
          className={"galerySlider"}
        />
      </div>
    </section>
  )
};

export default Galery;
