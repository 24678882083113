import React, { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";
import { faFacebookF } from '@fortawesome/free-brands-svg-icons'


import PhraseContext from "../../../context/phraseContext";
import FastuosaLogo01 from "../../../assets/img/logos/Fastuosa_logo_01.png";
import FastuosaLogo02 from "../../../assets/img/logos/Fastuosa_logo_02.png";
import "./Navbar.css";

const Navbar = () => {
    const [isSideNavBarShowing, setIsSideNavBarShowing] = useState(false)
    const [isNavbarIndex, setIsNavbarIndex] = useState(true);
    let scrollY = 0;
    const location = useLocation();
    const isIndexPage = location.pathname === "/";

    window.document.addEventListener("scroll", () => {
        scrollY = window.scrollY;
        if ( scrollY <= PhraseContext.height) {
            setIsNavbarIndex(true);
        } else {
            setIsNavbarIndex(false);
        }

    });

    return (
        <>
            <nav className="navbar">

                <div className={`navbar_icon${isNavbarIndex ? "_index" : ""}`}>
                    <FontAwesomeIcon
                        icon={faBars}
                        onClick={() => setIsSideNavBarShowing(true)}
                    />
                </div>
                <div className="navbar_links">
                    <NavLink
                        style={({ isActive }) => {
                            return {
                                color: isActive ? "#da794d" : "#f1ece5",
                            }
                        }}
                        to="/menu-pizzas"
                    >
                        Menú Pizza´s
                    </NavLink>
                    <NavLink
                        style={({ isActive }) => {
                            return {
                                color: isActive ? "#da794d" : "#f1ece5",
                            }
                        }}
                        to="/menu-cocina"
                    >
                        Menú Cocina
                    </NavLink>
                    <NavLink
                        className="navbar_responsive-menu-links-item-logo"
                        to="/"
                    >
                        <div className="navbar_logo" >
                            {
                                !isIndexPage ? (
                                    <img
                                        className="navbar_logo-image"
                                        src={FastuosaLogo01}
                                        alt="logo"
                                    />
                                ) : (
                                    <img
                                        className="navbar_logo-image"
                                        src={FastuosaLogo02}
                                        alt="logo"
                                    />
                                )
                            }

                        </div>
                    </NavLink>
                    <NavLink
                        style={({ isActive }) => {
                            return {
                                color: isActive ? "#da794d" : "#f1ece5",
                            }
                        }}
                        to="/sucursales"
                    >
                        Sucursales
                    </NavLink>
                    <NavLink
                        style={({ isActive }) => {
                            return {
                                color: isActive ? "#da794d" : "#f1ece5",
                            }
                        }}
                        to="/historia"
                    >
                        Historia
                    </NavLink>
                </div>
                {isSideNavBarShowing && (
                    <div className="navbar_responsive-menu">
                        <div className="navbar_responsive-menu-cross-icon">
                            <FontAwesomeIcon
                                icon={faTimes}
                                onClick={() => setIsSideNavBarShowing(false)}
                            />
                        </div>
                        <div className="navbar_responsive-menu-links">
                            <NavLink
                                onClick={() => setIsSideNavBarShowing(false)}
                                className="navbar_responsive-menu-links-item-logo"
                                to="/"
                            >
                                <div className="navbar_logo" >
                                    {
                                        !isIndexPage ? (
                                            <img
                                                className="navbar_logo-image"
                                                src={FastuosaLogo01}
                                                alt="logo"
                                            />
                                        ) : (
                                            <img
                                                className="navbar_logo-image"
                                                src={FastuosaLogo02}
                                                alt="logo"
                                            />
                                        )
                                    }

                                </div>
                            </NavLink>
                            <NavLink
                                style={({ isActive }) => {
                                    return {
                                        color: isActive ? "#da794d" : "#f1ece5",
                                    }
                                }}
                                onClick={() => setIsSideNavBarShowing(false)}
                                className="navbar_responsive-menu-links-item"
                                to="/menu-pizzas"
                            >
                                Menú Pizza´s
                            </NavLink>
                            <NavLink
                                style={({ isActive }) => {
                                    return {
                                        color: isActive ? "#da794d" : "#f1ece5",
                                    }
                                }}
                                onClick={() => setIsSideNavBarShowing(false)}
                                className="navbar_responsive-menu-links-item"
                                to="/menu-cocina"
                            >
                                Menú Cocina
                            </NavLink>
                            <NavLink
                                style={({ isActive }) => {
                                    return {
                                        color: isActive ? "#da794d" : "#f1ece5",
                                    }
                                }}
                                onClick={() => setIsSideNavBarShowing(false)}
                                className="navbar_responsive-menu-links-item"
                                to="/sucursales"
                            >
                                Sucursales
                            </NavLink>
                            <NavLink
                                style={({ isActive }) => {
                                    return {
                                        color: isActive ? "#da794d" : "#f1ece5",
                                    }
                                }}
                                onClick={() => setIsSideNavBarShowing(false)}
                                className="navbar_responsive-menu-links-item"
                                to="/historia"
                            >
                                Historia
                            </NavLink>
                        </div>
                        <div className="navbar_responsive-menu-social-media">
                            <a
                                href="https://www.facebook.com/Fastuosa-de-Sergios-103810384825497/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <FontAwesomeIcon
                                    icon={faFacebookF}
                                    onClick={() => setIsSideNavBarShowing(false)}
                                />
                            </a>
                        </div>
                    </div>
                )}
            </nav>
        </>
    );
}

export default Navbar;