const Map = ({ maps }) => {
    return (
        <iframe
            className="container_sucursales_map"
            title={maps.title}
            src={maps.src}
            style={{border:'0'}}
            allowFullScreen
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
    );
}

export default Map;