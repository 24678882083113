
import Slider from "../../Shared/Slider/Slider";
import Map from "./components/Map/Map";

import './sucursales.css';

import imgSlider from '../../../assets/img/Menus/46.jpg';
import Parallax from "../../Shared/Parallax/Parallax";

const Sucursales = () => {
    const phraseRef = Parallax();
    const MAPS = [
        {
            idMap: 1,
            src: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d232.66139182958943!2d-101.6484510388652!3d21.089340090670092!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x842bbe3a223bf3a3%3A0x24d172590327e532!2sBlvd.%20San%20Pedro%201114%2C%20Jardines%20de%20Jerez%20II%20y%20III%2C%2037530%20Le%C3%B3n%2C%20Gto.!5e0!3m2!1ses-419!2smx!4v1657602677442!5m2!1ses-419!2smx",
            title: "Sucursal 1",
            direccion: "Blvd. San pedro #1114 Col. Jardines de Jerez 2° Secc, León Gto.",
            telefonos: [{ id: 0, tel: "477-711-51-46" }, { id: 1, tel: "477-928-29-89" }],
            horarios: "2:00pm a 10:00pm Cerramos los martes"
        },
        {
            idMap: 2,
            src: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3722.6092946273443!2d-101.65901398464698!3d21.088261485966193!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x842bbe33ec538ce1%3A0x221e369f1471841e!2sAv.%20Oc%C3%A9ano%20Atl%C3%A1ntico%201701%2C%20Cumbres%20de%20la%20Pradera%2C%2037550%20Le%C3%B3n%2C%20Gto.!5e0!3m2!1ses-419!2smx!4v1657602742984!5m2!1ses-419!2smx',
            title: "Sucursal 2",
            direccion: "Av. Oceano Atlantico # 1701 Col. Cumbres de la Pradera, León Gto.",
            telefonos: [{ id: 0, tel: "477-761-87-03" }],
            horarios: "Sábados y Domingo de 2:00pm a 10:15pm."
        },
        {
            idMap: 3,
            src: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14893.117866349483!2d-101.60047493172773!3d21.061497493383307!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x842b97bd9708aa43%3A0x77381b803f755558!2sPlaza%20Mayorazgo!5e0!3m2!1ses-419!2smx!4v1695260716462!5m2!1ses-419!2smx',
            title: "Sucursal 3",
            direccion: "Blvd. El Mayorazgo #754 Plaza Mayorazgo Local 5, León Gto.",
            telefonos: [{ id: 0, tel: "477-909-54-49" }],
            horarios: "2:00pm a 10:00pm Cerramos los martes"
        },
    ];

    return (
        <>
            <Slider imgSlider={imgSlider} titile={"Sucursales"} isGalery={false} />
            <section className="section_sucursales" ref={phraseRef} >
                {MAPS.map((mapa) => {
                    return (
                        <div className="container_sucursales" key={mapa.idMap} >
                            <h3 className="container_sucursales_titile">{mapa.title}</h3>

                            <h4 className="container_sucursales_label">Dirección</h4>
                            <p className="container_sucursales_text">
                                {mapa.direccion}
                            </p>

                            <h4 className="container_sucursales_label">Horarios</h4>
                            <p className="container_sucursales_text">
                                {mapa.horarios}
                            </p>
                            <Map maps={mapa} />

                            <h4 className="container_sucursales_label">Teléfono</h4>
                            <p className="container_sucursales_text">
                                {mapa.telefonos.map(t => {
                                    return (
                                        <a href={`tel:+52${t.tel}`} key={t.id} className="container_sucursales_text_phone" >{t.tel}</a>
                                    );
                                })}</p>
                        </div>
                    );
                })}
            </section>
        </>
    );
}

export default Sucursales;