import Slider from "../../Shared/Slider/Slider";

import imgSlider from '../../../assets/img/Menus/32.jpg';
import Parallax from "../../Shared/Parallax/Parallax";

import './History.css';

const History = () => {
    const phraseRef = Parallax();

    return (
        <>
            <Slider imgSlider={imgSlider} titile={"Historia"} isGalery={false} />
            <section ref={phraseRef} >
                <article className="container_History">
                    <div>
                        <h3 className="container_History_title_text">Fastuosa Restaurante</h3>
                    </div>
                    <p className="container_History_text">
                        Fastuosa es un lugar único en la ciudad de León Guanajuato, distinguido por su variedad de riquísimas pizzas, nosotros apostamos por la creación de pizzas auténticas para degustar a todo tipo de paladares, incluso a los más exigentes.
                    </p>
                    <p className="container_History_text">
                        Desde hace más de una década, Fastuosa es líder por sus deliciosas pizzas, demostrando que, a través del paso de los años, no se podrá encontrar un lugar como este, con tanta variedad de pizzas y platillos.
                    </p>
                    <p className="container_History_text">
                        Dentro de nuestro menú de pizzas podemos encontrar las clásicas como la hawaiana, mexicana, otras como de mariscos, la suprema, y su especialidad la sergio´s y muchas más. Todas acompañadas de su delicioso chimichurri una especialidad de la casa.
                    </p>
                    <p className="container_History_text">
                        Por otra parte, contamos con una gran variedad de platillos por ejemplo hamburguesas, espaguetis, cremas, carnes, ensaladas y papas, con las que podemos acompañar a nuestra riquísima pizza. Todo esta preparado en el momento para que así tu puedas disfrutar de su sabor que encantara a tu paladar.
                    </p>
                    <p className="container_History_text">
                        Y si alguna vez gustas llevar el sabor de fastuosa a tus eventos ¡también lo puedes hacer! Ya que contamos con servicio a domicilio, así que no lo dudes más y déjate complacer por nuestro sabor especial. También te esperamos en nuestras sucursales…ven y disfruta de nuestro sabor inigualable.
                        <br />
                        Fastuosa una delicia al paladar...
                    </p>
                    <p className="container_History_text">
                        Ubicados en el <a href="https://goo.gl/maps/XHxZVMwni4nKdDHq9" target="_blank" rel="noreferrer" className="linkMap" >Blvd. San Pedro 1114</a>, <a href="https://goo.gl/maps/iYgSzKfPtPy5N1vQ6" target="_blank" rel="noreferrer" className="linkMap" >Océano atlántico 1701C</a> y <a href="https://maps.app.goo.gl/zknX2eCdaXazewAV9" target="_blank" rel="noreferrer" className="linkMap" >Plaza Mayorazgo 754 Local 5</a>.
                    </p>
                </article>
            </section>
        </>
    );
}

export default History;