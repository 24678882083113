import Banner from "../../Shared/Banner/Banner";

import img1 from '../../../assets/img/Menus/31.jpg';
import img2 from '../../../assets/img/Menus/41.jpg';
import img3 from '../../../assets/img/Menus/61.jpg';
import Galery from "./components/Galery/Galery";


const HomePage = () => {
    const imgs = [
        {
            id: 1,
            img: img1,
        },
        {
            id: 1,
            img: img2,
        },
        {
            id: 1,
            img: img3
        },
    ];

    return (
        <>
            <Banner imagenes={imgs} isHomeSlider={true} />
            <Galery />
        </>

    );
}

export default HomePage;