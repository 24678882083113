import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";


import HomePage from "./pages/HomePage/HomePage";
import Sucursales from "./pages/Sucursales/Sucursales";
import Footer from "./Shared/Footer/Footer";
import Navbar from "./Shared/Navbar/Navbar";
import PhraseContext from "../context/phraseContext";
import ScrollToTop from "./Shared/ScrollToTop/ScrollToTop";

import "./App.css"
import MenuCocina from "./pages/MenuCocina/MenuCocina";
import MenuPizzas from "./pages/MenuPizzas/MenuPizza";
import History from "./pages/History/History";

function App() {
  
  const phraseData = {
    height: 0,
  }

  return (
    <PhraseContext.Provider value={phraseData}>
      <div className="app">
        <Router>
          <ScrollToTop />
          <Navbar />
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/menu-pizzas" element={<MenuPizzas />} />
            <Route path="/menu-cocina" element={<MenuCocina />} />
            <Route path="/sucursales" element={<Sucursales />} />
            <Route path="/historia" element={<History />} />
          </Routes>
          <Footer />
        </Router>
      </div>
    </PhraseContext.Provider>
  );
}

export default App;
