
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation, Lazy } from "swiper";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "./Slider.css";

const Slider = ({ imgs = [], imgSlider, titile = "", isGalery = false, slidesPerView, className, ...rest }) => {

    const renderImgsGalery = () => {
        return (
            <Swiper
                slidesPerView={slidesPerView}
                spaceBetween={30}
                autoplay={{
                    delay: 3000,
                    disableOnInteraction: false,
                }}
                pagination={{
                    clickable: true,
                }}
                navigation={true}
                modules={[Autoplay, Pagination, Navigation, Lazy]}
                className={className}
                lazy={true}
            >
                {
                    imgs.map(img => {
                        return (
                            <SwiperSlide key={img.id}>
                                <img loading="lazy" src={img.imagen} alt={img.name} />
                            </SwiperSlide>
                        );
                    })
                }
            </Swiper>
        );
    };

    const renderImgSilider = () => {
        return (
            <Swiper
                slidesPerView={1}
                spaceBetween={0}
                className="slider_widthfull"
                {...rest}
            >

                <SwiperSlide >
                    <div className="slide-container">
                        <p className="slider_section_text">
                            {titile}
                        </p>
                        <img loading="lazy" src={imgSlider} alt="Menú" />
                    </div>
                </SwiperSlide>
            </Swiper>
        );
    };

    return (
        <section className="slider_section" >
            {isGalery ? (
                renderImgsGalery()
            ) : (
                renderImgSilider()
            )}
        </section >
    );
}

export default Slider;