import { useEffect, useState } from "react";
import Parallax from "../../Shared/Parallax/Parallax";
import imgSlider from '../../../assets/img/Menus/15.jpg';
import Slider from "../../Shared/Slider/Slider";
import MenuCategorias from "./Components/MenuCategorias/MenuCategorias";
import Menu from "./Components/Menu/Menu";

import Pastas from '../../../assets/img/Menus/6.jpg';
import Cremas from '../../../assets/img/Menus/38.jpg';
import Carnes from '../../../assets/img/Menus/81.jpg';
import Hamburgesas from '../../../assets/img/Menus/9.jpg';
import Otros from '../../../assets/img/Menus/76.jpg';

const IMAGEN_PLATILLO = [
    {
        imagen: Pastas,
        nombreCategoria: "Pastas",
        categoria: 1
    },
    {
        imagen: Cremas,
        nombreCategoria: "Cremas",
        categoria: 2
    },
    {
        imagen: Carnes,
        nombreCategoria: "Carnes",
        categoria: 3
    },
    {
        imagen: Hamburgesas,
        nombreCategoria: "Hamburgesas",
        categoria: 4
    },
    {
        imagen: Otros,
        nombreCategoria: "Otros",
        categoria: 5
    },
];

const MenuCocina = () => {
    const phraseRef = Parallax();
    const [categoriaSelected, setCategoriaSelected] = useState(4);
    const [categoriasCocina, setCategoriasCocina] = useState([])
    const [menuCocina, setMenuCocina] = useState([])
    const getData = () => {
        fetch('../../../../data.json'
            , {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            }
        )
            .then(response => {
                return response.json();
            })
            .then(myJson => {
                const { CATEGORIAS_COCINA, MENU_COCINA } = myJson
                setMenuCocina(MENU_COCINA)
                setCategoriasCocina(CATEGORIAS_COCINA)
            });
    }

    useEffect(() => {
        getData()
    }, []);

    return (
        <>
            <Slider imgSlider={imgSlider} titile={"Menú Cocina"} isGalery={false} />
            <section className="section_menu_cocina" ref={phraseRef} >
                <MenuCategorias elementos={categoriasCocina} isChanged={setCategoriaSelected} />
                <Menu menu={menuCocina} imagenes={IMAGEN_PLATILLO} categoria={categoriaSelected} />
            </section>
        </>
    );
}

export default MenuCocina;