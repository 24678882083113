import './Menu.css';

const Menu = ({ menu = [], categoria = 1, imagenes }) => {
    const img = imagenes.find(i => i.categoria === categoria);
    return (
        <article className="article_Menu">
            <div className='container_image'>
                <p className='container_image_title'>{img.nombreCategoria}</p>
                <img loading="lazy" className='container_image_img' src={img.imagen} alt={categoria} />
            </div>
            <div className='container_menu'>
                {menu.map(item => {
                    if (item.idCategoria === categoria) {
                        return (
                            <div key={item.id}>
                                <p className='container_Menu_Platillo'>
                                    {item.nombre}
                                    <span className='container_Menu_Platillo_Precio'>
                                        {item.precio}
                                    </span>
                                </p>
                                <p className='container_Menu_Descripcion'>{item.descripcion}</p>
                            </div>
                        );
                    } else {
                        return null;
                    }
                })}
            </div>
        </article>
    );
}

export default Menu;