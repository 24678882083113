import './MenuCategorias.css';

const MenuCategorias = ({ elementos = [], isChanged = () => { } }) => {

    const handleSelectedCategoria = (event,id) => {
        event.preventDefault()
        isChanged(id);
    }
    return (
        <article className="container_menuCocina">
            <ul className="menuCategorias">
                {elementos.map(item => {
                    return (
                        <oi key={item.id} >
                            <a
                                className="menuCategorias_elements"
                                href="/"
                                onClick={(event) => handleSelectedCategoria( event, item.id)}
                            >
                                {item.nombre}
                            </a>
                        </oi>
                    );
                })}
            </ul>
        </article>
    );
}

export default MenuCategorias;